.align-items-center,
.page-header,
.loader-demo-box,
.list-wrapper ul li,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .dropdown-menu.navbar-dropdown
  .dropdown-item {
  color: black !important;
}
.main-loader {
  width: 50px;
  height: 50px;
}
.overlay_screen {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transform: translate(-50%,-50%); */
}
.react-tel-input .form-control {
  font-size: 16px !important;
  background: #ffffff !important;
  border: 1px solid #ebedf2 !important;
  border-radius: 0px !important;
  width: 100% !important;
  padding: 14.5px 0px 14.5px 55px !important;
  transition: box-shadow ease 0.25s, border-color ease 0.25s !important;
  color: #495057 !important;
}
.filler_search {
  padding: 9px;
  margin-top: 2px;
}
img.img-custom {
  height: 130px;
  width: 130px;
  padding: 0px;
  margin-left: -2px;
  object-fit: cover;
}
img.mb-2.rounded.img-view.user-profileimage {
  width: 60px;
  height: 60px;
  border-radius: 100px !important;
  object-fit: cover;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
}

.pagination-box-review {
  float: right;
  margin-top: 10px;
}
img.custom-img {
  width: 40px;
  height: 40px;
}
button.btn.btn-gradient-primary.float-right {
  float: right;
}
.class-time-date-time {
  background-color: #fff !important;
  border: 1px solid #d3d5db !important;
}
i.mdi.mdi-checkbox-blank-circle.primary {
  color: #e997a7;
}
i.mdi.mdi-checkbox-blank-circle.success {
  color: #83b79f;
}
i.mdi.mdi-checkbox-blank-circle.info {
  color: #80b8e5;
}
.media-body.task.primary {
  background: #fbd2da;
  color: #322f2f;
  padding: 12px;
}

p.float-right {
  float: right;
  font-weight: 700;
  margin-bottom: 0px;
  cursor: pointer;
  margin-top: 10px;
}
.media-body.task.success {
  background: #b1dbc8;
  color: #322f2f;
  padding: 12px;
}

.media-body.task.info {
  background: #b9e0ff;
  color: #322f2f;
  padding: 12px;
}

video.img-custom-video {
  width: 150px;
  height: 100px;
}
img.rounded.img-view {
  width: 100px;
  height: 100px;
}
.match_tennis_list p {
  line-height: 8px;
}
.text-danger.err {
  font-size: 14px;
  padding: 2px;
  color: #c31d1d !important;
}
video.mb-2.rounded.img-view-video {
  width: 200px;
  height: 100px;
}

.ck.ck-editor__editable_inline {
  border: 1px solid transparent;
  overflow: auto;
  padding: 0 var(--ck-spacing-standard);
  height: 200px !important;
}
li.breadcrumb-item a {
  color: #860000;
}
.page-item.active .page-link {
  background-color: #860000 !important;
  border-color: #860000 !important;
}
img.style-login-logo {
  width: 350px;
  margin-left: 16px;
}
img.img-sixe-dashboard {
  width: 140px;
  height: 130px;
}
a.dashboard_tags {
  color: #363333;
  text-decoration: none;
}
.dashboard_tags_back-color {
  background-color: #fff !important;
}
tr.cursor-pointer {
  cursor: pointer;
}
img.img-custom.image-SIZE {
  margin-left: 27px;
  margin-top: -22px;
}
.cursor-pointer {
  cursor: pointer;
}
.add-items.d-flex.form-group-back {
  background: #fff;
}
.text-center.navbar-brand-wrapper.d-flex.align-items-center.justify-content-center {
  background: #101010 !important;
}
.login-background-color {
  background: #101010 !important;
}
.sidebar-back {
  background: #101010 !important;
}
img.menu-icon-img {
  font-size: 1.125rem;
  line-height: 1;
  margin-left: auto;
  color: #bba8bff5;
}
.authLayout {
  border-radius: 40px;
}
.authLayout .imgWrpper img {
  max-height: 600px;
  object-fit: cover;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
}
.navbar .navbar-brand-wrapper .navbar-brand img {
  object-fit: contain;
}
.nav-profile-img img {
  object-fit: cover;
}
button.btn.btn-sm.btn-gradient-success {
  /* color: green; */
  background: #1d971d;
}
/* .sidebar {
  position: fixed;
    top: 70px;
} */
.card-body {
  padding: 15px !important;
}
.mr-top {
  margin-top: 21px;
}

.table-responsive {
  overflow-x: auto;
}

.sidebar {
  position: fixed;
}
.task-notes {
  margin-top: 16px;
}
.d-flex.task-notes-list {
  margin: 25px 0px 0px 0px;
}
p.task-notes-list {
  margin-top: 10px;
  font-size: 15px;
}
.d-flex.align-items-center.text-muted.font-weight-light.float-right {
  float: right;
  margin-left: 30px;
}
.margin-bottom {
  margin-bottom: 30px;
}

.image-custom-css {
  width: 120px;
  height: 120px;
  margin-top: 20px;
}
.text-area-height {
  min-height: 150px !important;
}

/* ajay 27-3-2024 */
.sidebar > .nav {
  overflow-y: scroll;
  height: calc(100vh - 100px);
}
.sidebar .nav::-webkit-scrollbar {
  width: 0px;
}
.sidebar .nav::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.sidebar .nav::-webkit-scrollbar-thumb {
  background: #888;
}
.sidebar .nav::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.sidebar .nav .nav-item:not(:last-child) {
  margin-bottom: 8px;
}

.sidebar .nav .nav-item .nav-link {
  padding: 14px;
}
.content-wrapper,
.footer {
  background: #fff;
}
.navbar .navbar-menu-wrapper {
  background-color: transparent;
}
.main-panel {
  border-radius: 50px !important;
  background: #000;
  overflow: hidden;
  margin: 20px 0;
}
.page-body-wrapper {
  background: #000;
  padding-right: 18px !important;
  padding-bottom: 50px;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .dropdown-toggle:after {
  color: #000;
}
.mdi-power:before,
.menu-icon:before {
  color: #fff;
}
.nav-logout .mdi-power:before {
  color: #000;
}
.sidebar .nav .nav-item.active .menu-icon:before,
.sidebar .nav .nav-item:hover .menu-icon:before {
  color: #000;
}
.badge-dark {
  background: #101010;
}
.custom_radius {
  border-radius: 16px;
}
img.img-fluid.thumb_man {
  transform: scale(1.1);
}
.card {
  background-color: #ffffff6b;
}
label.badge.badge-success {
  border: 1px solid #8abc82;
  background: #8abc82;
  border-radius: 100px;
  text-transform: capitalize;
}


/* label.badge.badge-danger {
  border: 1px solid #d30d11;
  background: #d30d11;
  border-radius: 100px;
  text-transform: capitalize;
} */
label.badge.badge-danger {
  border: 1px solid #d30d11;
  background: #9b0a0d;
  border-radius: 100px;
  text-transform: capitalize;
  color: #f3f3f3;
}
label.badge.badge-pending {
  border: 1px solid #fce87a;
  background: #fce87a;
  border-radius: 100px;
  text-transform: capitalize;
  color: #383737;
}
label.badge.badge-close {
  border: 1px solid #cecece;
  background: #cecece;
  border-radius: 100px;
  text-transform: capitalize;
  color: #383737;
}
label.col-form-label a,
a.view-btn {
  color: #880002;
}
.card.card_sidebar {
  background: transparent;
}

.sidebar .nav .nav-item .nav-link.collapsed i.menu-arrow {
  transform: rotate(0deg);
}
.sidebar .nav .nav-item .nav-link i.menu-arrow {
  transform: rotate(-90deg);
}

.filter_drop_filter {
  width: 160px;
}
.button_cta button {
  color: #fff !important;
  padding: 16px 25px !important;
}

.button_cta a {
  text-decoration: none;
}
button.edit_card_cta {
  background: transparent;
  color: #000 !important;
}
.image-cust {
  margin-top: 21px;
}
.sidebar .nav .nav-item:hover {
  background: transparent;
}

.sidebar .nav .nav-item:hover > a {
  background: #fff;
}

.symbol_img {
  width: 36px;
  height: 36px;
  border-radius: 100px;
}
.symbol_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.table_side_bar tbody tr td {
  padding: 6px 11px;
  vertical-align: middle;
}
.card.card_sidebar tbody tr td,
.card.card_sidebar thead tr th {
  border: none;
}
.graph_card tbody tr td,
.graph_card label,
.graph_card p {
  font-size: 13px;
}
.table_side_bar tbody tr td {
  font-size: 13px;
  color: #130a2b94 !important;
}
.card.card_sidebar thead tr th {
  font-size: 13px;
  font-weight: normal;
  color: #000;
  opacity: 0.5;
}
h4.card-title.sidebar_title {
  font-size: 25px;
}
.graph_card {
  background: transparent;
}
.common_tabel_th thead tr th {
  color: #505470;
  font-size: 12px;
}
.border_custom {
  box-shadow: -1px 1px 2px #cccc, 1px -1px 2px #cccc;
}

.react-tabs__tab--selected {
  background: unset !important;
  border: none !important;
  color: #2e3a59 !important;
  position: relative;
}
.react-tabs__tab--selected::before {
  content: "";
  height: 5px;
  width: 100%;
  background-color: #2e3a59;
  position: absolute;
  bottom: 0;
  left: 0;
}
.react-tabs__tab {
  padding: 13px 12px !important;
}

.axis_bank {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  overflow: hidden;
}
.axis_bank img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.axix_modal_content ul {
  padding: 0;
  margin: 0;
}
.axix_modal_content ul li {
  list-style-type: none;
}
.axix_modal_content ul li label.col-form-label {
  padding: 12px 0 !important;
}
.axis_modal .modal-content {
  border-radius: 20px;
}
.sidebar .nav .nav-item .nav-link,
.sidebar .nav .nav-item.active,
.sidebar .nav .nav-item:hover {
  border-radius: 50px 0 0 50px;
}
.payment_status {
  border: 1px solid #8abc8233 !important;
  background: #8abc8233 !important;
  border-radius: 100px;
  color: #8abc82;
}
.red_col {
  border: 1px solid #d30d1166 !important;
  background: #d30d1166 !important;
  border-radius: 100px;
  color: #d30d11;
}
.common_th thead tr th {
  background: #f7f8fa;
}
.green_status {
  color: #279646;
  font-weight: 600;
}
.main-panel {
  border-radius: 50px;
}
.sidebar-back.active {
  overflow: hidden;
}
a.navbar-brand.brand-logo img {
  margin: 12px 0px 12px 12px !important;
  height: 50px !important;
}
body,
html {
  height: 100%;
}
.sidebar .nav .nav-item .nav-link .menu-icon:before,
.sidebar .nav .nav-item .nav-link:hover .menu-icon:before {
  color: #fff !important;
}
a.close_btn {
  position: absolute;
  right: 14px;
}
.fixed_height {
  height: 100%;
  min-height: 720px;
}
/* ajay 27-3-2024 */

.table-responsive {
  overflow-x: auto;
}
.actionSticky th:last-child,
.actionSticky td:last-child {
  position: sticky;
  top: 0;
  background: #e9e9e9;
  right: 0;
}
.actionSticky th:last-child {
  background-color: #bbc1e5;
}
.modalWidthCstm .modal-dialog {
  max-width: 700px;
}
.sidebar .nav > .nav-item .collapse a:hover {
  background: #fff;
  color: #000;
  margin-right: 0 !important;
}
a.dashboard_tags .card-body {
  padding: 15px 0px !important;
}
.imgWrpp img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.imgWrpp {
  width: 48px;
  height: 48px;
}
.left h2 {
  margin: 0;
  font-size: 23px;
}
.lastOpen {
  height: 490px;
  overflow-x: scroll;
  overflow-y: scroll;
}
.lastOpen::-webkit-scrollbar {
  width: 0;
}
.fixed_height table tbody tr td {
  text-align: center;
}
.button_red {
  width: 100%;
}
.not-fount-permission {
  width: 50%;
  margin: 0 auto;
}
.text-arr-permission {
  text-align: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 25%;
  font-size: 28px;
}
span.py-2.spanclass {
  text-align: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 45%;
  font-size: 16px;
}
/* .graph_card .filter_drop_filter {
  width: 126px;
} */
/* .graph_card .filter_drop_filter input {
  padding: 0.94rem 1.0rem;
} */
/* .card.graph_card .d-flex {
  align-items: flex-end !important;
} */
/* .graph_card .filter_drop_filter input {
  padding: 0.94rem 1.0rem;
}
.card.graph_card .d-flex {
  align-items: flex-end !important;
  padding-bottom: 5px;
}
.card.graph_card .d-flex h4.card-title {
  margin-bottom: 0;
}
.card.graph_card .d-flex h4.card-title .fillte_main {
  padding-bottom: 0;
} */
/* Optional: Adjust the styling of the table for better mobile view */

@media (max-width: 1360px) {
  .button_red {
    width: 100%;
    padding: 10px 5px;
    font-size: 13px !important;
  }
}

@media (max-width: 1169px) {
  .dashboard_first_row {
    flex-direction: column;
  }
  .dashboard_first_row .column_eight,
  .dashboard_first_row .column_four {
    width: 100%;
  }
  .profile_sider .row {
    padding: 8px 16px;
  }
  .profile_sider .row .col-md-6 {
    padding: 0px !important;
  }
  .button_red {
    font-size: 11px !important;
  }
}

@media (max-width: 992px) {
  .authLayout {
    border-radius: 20px;
  }
  .fixed-top {
    background: #000;
  }
  .main-panel {
    border-radius: unset !important;
    margin: 0;
  }
  .page-body-wrapper {
    padding-right: 0 !important;
  }
}

@media (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }

  .table {
    margin-bottom: 0;
  }
  .button_red {
    width: 50%;
  }
}

@media (max-width: 390px) {
  .button_red {
    width: 60%;
  }
}

@media (max-width: 350px) {
  .button_red {
    width: 80%;
  }
}
